<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="providerSection === 1" :title-value="'Providers'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card flat v-else>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-school</v-icon>
        <v-toolbar-title class="pl-2">Bookmarked Providers</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="providerSection !== 2" flat dense>
      <!--  Filter Icon -->
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="700px">
          <v-card-text>
            <v-container fluid>
              <!--Clear Filter Button-->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--License Number-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_licenseNumber"
                        label="License #"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--License Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLicense"
                        label="Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--County ID-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="countiesStore.countiesValueList"
                        item-text="d_name"
                        item-value="id"
                        label="County"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_countyID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Type-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.facilityTypes"
                        item-text="value"
                        item-value="id"
                        label="Operation Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_type"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Rating-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="ratingsStore.ratingsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Rating"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_ratingID"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.providerStatus"
                        item-text="value"
                        item-value="id"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Email-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_email"
                        label="Email Address"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Contact Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="contactName"
                        label="Contact Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="actionTypesStore.actionTypesValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Alert"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_actionTypeID"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.valueListItems[26]"
                        item-text="d_name"
                        item-value="d_name"
                        label="Contracted Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_contractedStatus"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="4"
        :enabled="providersStore.providers.data && providersStore.providers.data.length > 0"
      />
      <!--Provider Pop Up Dialog-->
      <provider-entry :provider-section="1" />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="providersStore.providers.data && providersStore.providers.data.length > 0"
      :showTotal="providerSection === 2 ? false : true"
      :currentPage="providersStore.providers.current_page"
      :lastPage="providersStore.providers.last_page"
      :total="providersStore.providers.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <v-list
        v-if="providersStore.providers.data && providersStore.providers.data.length > 0"
        class="py-2"
        three-line
      >
        <template v-for="(item, index) in providersStore.providers.data">
          <v-list-item :key="item.id" @click="detail(item.uuid)" class="my-1">
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4">{{
                item.d_licenseNumber
              }}</v-list-item-title>
              <v-list-item-title class="light-blue--text text--darken-4">{{
                item.d_nameLicense
              }}</v-list-item-title>
              <v-list-item-title>{{ item.d_facilityStreet1 }}</v-list-item-title>
              <v-list-item-title
                >{{ item.d_facilityCity }} {{ item.d_facilityState }}
                {{ item.d_facilityPostalCode }}</v-list-item-title
              >
              <v-list-item-title
                class="text-caption grey--text"
                :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
              >
                Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
                {{ $_formatTimestamp(item.updated_at) }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                ><span class="text-body-1">{{
                  $_switchProviderType(item.f_type)
                }}</span></v-list-item-action-text
              >
              <v-list-item-action-text>
                <span v-if="item.f_status === 1" class="text-body-1">Prospective</span>
                <span
                  v-if="item.f_status === 2"
                  class="text-body-1 font-weight-bold green--text text--lighten-1"
                  >ACTIVE</span
                >
                <span v-if="item.f_status === 3" class="text-body-1">Under Review</span>
                <span v-if="item.f_status === 4" class="text-body-1 error--text text--darken-1"
                  >Inactive</span
                >
              </v-list-item-action-text>
              <v-rating
                v-if="
                  item.fk_ratingID === 1 ||
                    item.fk_ratingID === 2 ||
                    item.fk_ratingID === 3 ||
                    item.fk_ratingID === 4 ||
                    item.fk_ratingID === 5
                "
                :value="$_switchRating(item.fk_ratingID)"
                color="yellow darken-2"
                readonly
                small
                background-color="grey lighten-1"
              >
              </v-rating>
              <span v-else>{{ item.rating ? item.rating.d_name : "" }}</span>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </v-list>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Providers Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ExportExcel from "@/components/ExportExcel";
import ProviderEntry from "@/components/ProviderEntry";

const { mapFields } = createHelpers({
  getterType: "providersStore/getFilterField",
  mutationType: "providersStore/updateFilterField"
});

export default {
  name: "Providers",
  components: {
    TitleBar,
    Pagination,
    ExportExcel,
    ProviderEntry
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      countiesStore: state => state.countiesStore,
      providersStore: state => state.providersStore,
      valueListsStore: state => state.valueListsStore,
      ratingsStore: state => state.ratingsStore,
      progressStore: state => state.progressStore,
      actionTypesStore: state => state.actionTypesStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_userID",
      "d_licenseNumber",
      "d_nameLicense",
      "fk_countyID",
      "f_type",
      "fk_ratingID",
      "f_status",
      "d_email",
      "fk_actionTypeID",
      "contactName",
      "d_contractedStatus",
      "fk_reportID",
      "f_month",
      "d_year"
    ])
  },
  props: {
    /* All list = 1, Dashboard = 2 */
    providerSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      /* If not running from dashboard */
      if (this.providerSection !== 2) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Pull providers list */
      const filters = this.$store.getters["providersStore/getFilters"];
      await this.$store.dispatch("providersStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      /* All list */
      if (this.providerSection === 1) {
        this.fk_userID = "";

        /* Grab counties for value list */
        const counties = this.$store.getters["countiesStore/getCountyValueList"];
        if (counties.length === 0) {
          await this.$store.dispatch("countiesStore/valueList");
        }
        /* Pull Provider/Facility rating value list */
        const type = {
          type: 2
        };
        await this.$store.dispatch("ratingsStore/valueList", type);

        const f_type = {
          f_type: 1
        };
        await this.$store.dispatch("actionTypesStore/valueList", f_type);

        const data = {
          id: [26]
        };
        await this.$store.dispatch("valueListsStore/items", data);
      } else {
        /* Dashboard */
        const user = this.$store.getters["usersStore/getAuthUser"];
        this.fk_userID = user.id;
      }
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.fk_countyID = "";
      this.f_type = "";
      this.fk_ratingID = "";
      this.f_status = "";
      this.d_email = "";
      this.fk_actionTypeID = "";
      this.contactName = "";
      this.d_contractedStatus = "";
      this.fk_reportID = "";
      this.f_month = "";
      this.d_year = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.providerSection !== this.section && this.section !== 99) {
        this.clearFilterColumns();
        this.$store.dispatch("providersStore/clearProviders");
      }
      /* Save section in store */
      this.section = this.providerSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.fk_countyID ||
        this.f_type ||
        this.fk_ratingID ||
        this.f_status ||
        this.f_status === 0 ||
        this.d_email ||
        this.fk_actionTypeID ||
        this.contactName ||
        this.d_contractedStatus ||
        this.fk_reportID
      ) {
        return true;
      }
      return false;
    },

    detail(uuid) {
      this.$store.dispatch("providersStore/currentIndex", uuid);
      this.$router.push({ name: "provider", params: { providerUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
