<template>

<div>

  <providers :providerSection="1"></providers>

</div>

</template>

<script>
import Providers from '@/components/Providers';

export default {
  name: 'ProvidersList',
  components: {
    Providers,
  },
};
</script>

<style scoped>

</style>
